import { render, staticRenderFns } from "./CustomersAccountsTable.vue?vue&type=template&id=48d2aace&scoped=true&"
import script from "./CustomersAccountsTable.vue?vue&type=script&lang=js&"
export * from "./CustomersAccountsTable.vue?vue&type=script&lang=js&"
import style0 from "./CustomersAccountsTable.vue?vue&type=style&index=0&id=48d2aace&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48d2aace",
  null
  
)

export default component.exports